<template>
  <b-container id="requested-jobs">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <h1 class="pb-2">
      <b-row class="align-items-end">
        <b-col cols="11">Requested Jobs</b-col>
        <b-col class="text-right">
          <b-button variant="light" @click="fetchData" :disabled="busy" class="border mr-auto">
            <font-awesome-icon class="text-muted" :icon="['fas', 'sync']" />
          </b-button>
        </b-col>
      </b-row>
    </h1>
    <p>
      This page shows all running or queued accounting platform extract jobs and textract jobs.
      <br />If there is no data, no jobs are running or are currently queued.
    </p>
    <b-table
      :items="jobs"
      :fields="fields"
      :busy="busy"
      sort-by="created"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #table-busy>
        <Busy primary text="Loading..." />
      </template>
      <template #cell(type)="row">{{
        formatCoboldCase(row.value).replace("Qb", "QuickBooks")
      }}</template>
      <template #cell(organisationId)="row">
        <b-link :to="{ name: 'organisation-details', params: { organisationId: row.value } }">
          {{ orgShortName(row.item.orgName) }}
        </b-link>
      </template>
      <template #cell(created)="row">{{ formatDateTimeToMin(row.value) }}</template>
      <template #cell(started)="row">
        <b-badge v-if="row.value == 'QUEUED'" class="status-badge badge-warning">QUEUED</b-badge>
        <div v-else>
          <b-badge class="status-badge" variant="primary">IN PROGRESS</b-badge>
          <span class="small pl-2 pt-1">
            <font-awesome-icon :icon="['far', 'clock']" class="ml-1" />
            {{ getMinutesElapsed(row.value) }} min
          </span>
        </div>
      </template>
      <template #cell(actions)="row">
        <b-button
          v-if="!row.item.started"
          variant="outline-primary"
          size="sm"
          @click="showCancelJobModal(row.item)"
          :disabled="isJobBusy(row.item)"
          >Cancel</b-button
        >
      </template>
    </b-table>

    <b-pagination
      v-if="jobs.length > perPage"
      align="right"
      :total-rows="jobs.length"
      :per-page="perPage"
      v-model="currentPage"
      :first-text="'\u00AB'"
      :prev-text="'\u2039'"
      :next-text="'\u203A'"
      :last-text="'\u00BB'"
      :ellipsis-text="'\u2026'"
    />

    <b-modal v-model="cancelJobParams.showModal" @ok="cancelJob" title="Cancel Job"
      >Are you sure you want to cancel {{ this.cancelJobParams.jobType }} for {{ this.cancelJobParams.orgName }}?</b-modal
    >
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";

export default {
  name: "RequestedJobs",
  components: { Busy },
  data() {
    return {
      title: "Requested Jobs",
      busy: false,
      jobs: [],
      fields: [
        {
          key: "type"
        },
        {
          key: "organisationId",
          label: "Organisation"
        },
        {
          key: "createdByUserEmail",
          label: "Requested by"
        },
        {
          key: "created",
          label: "Request time",
          sortable: true
        },
        {
          key: "started",
          label: "",
          formatter: x => (x ? x : "QUEUED")
        },
        {
          key: "actions",
          label: ""
        }
      ],
      currentPage: 1,
      perPage: 50,

      cancelJobParams: {
        showModal: false,
        orgId: null,
        orgName: null,
        jobType: null,
        busy: false
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    formatCoboldCase: FormatHelper.formatCoboldCase,
    formatDateTimeToMin: FormatHelper.formatDateTimeToMin,
    orgShortName: FormatHelper.orgShortName,

    async fetchData() {
      this.busy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}requestedjobs`)
        .then(response => {
          Console.log(response);
          this.jobs = response.data.jobs;
        })
        .catch(e => {
          Console.error(e);
          this.showMessage("There was an issue fetching requested job data.", "warning");
        })
        .finally(() => (this.busy = false));
    },

    async cancelJob() {
      this.cancelJobParams.busy = true;
      const client = await ApiHelper.http();
      await client
        .delete(
          `${ApiHelper.endPoint()}requestedjobs?organisationId=${
            this.cancelJobParams.orgId
          }&jobType=${this.cancelJobParams.jobType}`
        )
        .then(response => {
          this.showMessage(response.data.message, "success");
        })
        .catch(e => {
          this.showMessage(e.response.data.message, "warning");
        })
        .finally(() => {
          this.cancelJobParams.busy = false;
          this.fetchData();
        });
    },

    getMinutesElapsed(date) {
      let timeDiff = (Date.now() - Date.parse(date)) / 1000 / 60 - 60; // time difference in mins
      return Math.floor(timeDiff);
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    showCancelJobModal(job) {
      this.cancelJobParams.orgId = job.organisationId;
      this.cancelJobParams.jobType = job.type;
      this.cancelJobParams.orgName = job.orgName;
      this.cancelJobParams.showModal = true;
    },

    isJobBusy(job) {
      return (
        this.cancelJobParams.busy &&
        this.cancelJobParams.jobType == job.type &&
        this.cancelJobParams.organisationId == job.organisationId
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#requested-jobs {
  @include badge-styles;
}
</style>
